@use "sass:math";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,400;1,700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

body, html {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

a, .link {
  cursor: pointer;
  color: #166ec1;
  transition: color .05s linear;

  &:visited {
    color: #166ec1;
  }

  &:hover {
    color: #0056a3;
  }

  &:active {
    color: #3b92e2;
  }
}


$emoji-normal-size: 1em;


.emoji {
  height: $emoji-normal-size;
  width: $emoji-normal-size;
  margin: 0 .05em 0 .1em;
  vertical-align: -0.1em;
}
